@use "global" as *;

.section {
	margin-top: 0;
	// padding: 0 $padding-pc;
	// padding: 0 min(vw(strip-unit($canvas-size-pc), strip-unit($padding-pc)), $padding-pc);
	padding: 0 clamp(24px, vw(strip-unit($canvas-size-pc), strip-unit($padding-pc)), $padding-pc);

	position: relative;

	@include mq("sp") {
		margin-top: 0;

		// padding: 0 $padding-sp;
		padding: 0 min(vw(strip-unit($canvas-size-sp), strip-unit($padding-sp)), $padding-sp);
	}
}

.section__inner {
	width: 100%;
	max-width: $inner-max-width-pc;
	padding: $padding-top-section-inner-pc 0;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	position: relative;

	@include mq("sp") {
		margin: 0 auto;
		padding: $padding-top-section-inner-sp 0;
	}
}

// セクション背景（左右にずれている背景）
.sectionBackground__right::before,
.sectionBackground__left::before {
	content: "";
	max-width: calc(50vw + 780px);
	// 50vw + 白背景の半分
	// width: calc(100vw - clamp(24px, vw(strip-unit($canvas-size-pc), strip-unit($padding-pc)), $padding-pc) / 2);
	width: min(calc(50vw + 560px + vw(strip-unit($canvas-size-pc), 220)), calc(100vw - clamp(24px, vw(strip-unit($canvas-size-pc), strip-unit($padding-pc)), $padding-pc) / 2));
	// 100vw - paddingの半分（paddingの半分余白を空けるため）
	height: calc(100% - $padding-top-section-inner-pc);
	// 100% - セクション上端からセクションタイトル半分までの長さ
	background-color: $color-white;

	position: absolute;
	z-index: 0;
	bottom: 0;

	@include mq("sp") {
		// width: calc(100vw - clamp(18px, vw(strip-unit($canvas-size-sp), strip-unit($padding-sp)), $padding-sp) / 2);
		width: calc(100vw - min(18px, vw(strip-unit($canvas-size-sp), 18)));
		height: calc(100% - $padding-top-section-inner-sp);
	}
}
.sectionBackground__right::before {
	right: 0;
}
.sectionBackground__left::before {
	left: 0;
}

.sectionBackground__right .section__title,
.sectionBackground__left .section__title {
	margin-top: -37px;

	@include mq("sp") {
		margin-top: -13px;
	}
}

.section__title {
	width: 100%;

	font-family: $font-family-eg;
	font-size: clamp(calc(68 * 0.8px), vw(strip-unit($canvas-size-pc), 68), 68px);
	font-weight: $font-weight-bold;
	// margin-top: 40px;
	// line-height: calc( / );
	letter-spacing: calc(60 / 1000 * 1em);

	@include mq("sp") {
		font-size: clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-sp), 24), calc(24 * 1.2px));
	}
}

.section__body {
	margin-top: $padding-top-section-body-pc;
	width: 100%;
	// max-width: $inner-max-width-pc;
	// margin: 0 auto;

	@include mq("sp") {
		margin-top: $padding-top-section-body-sp;
	}
}
