@use "global" as *;

.section__streetView {
	background-color: $color-offWhite;
}

.section.section__streetView {
	@include mq("sp") {
		// padding: 0 min(vw(strip-unit($canvas-size-sp), 54), 54px);
		// padding: clamp(calc(54 * 0.8px), vw(strip-unit($canvas-size-sp), 54), calc(54 * 1.2px));
		padding: 0;
	}
}

.section.section__streetView .section__inner {
	width: calc(1024 / 1120 * 100%);
	padding-bottom: 0;

	@include mq("sp") {
		// width: calc(267 / 315 * 100%);
		width: 100%;
		padding: 71px 0 0;
	}
}

.streetView--rapper {
	width: 100%;
	max-width: $streetView-max-width-pc;
}
