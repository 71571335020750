@use "global" as *;

.section.section__trainer {
	background-color: $color-offWhite;
}

.trainer__items {
	width: 100%;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	@include mq("sp") {
		flex-direction: column;
	}
}

.trainer__item {
	width: 48%;
	height: auto;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;

	@include mq("sp") {
		width: 100%;
		max-width: 317px;
		margin: 0 auto;
	}
}

.trainer__item:nth-of-type(n + 3) {
	margin-top: 104px;
}
.trainer__item:nth-of-type(n + 2) {
	@include mq("sp") {
		margin-top: 64px;
	}
}

.trainer__content--wrapper {
	width: clamp(calc(380 * 0.81px), vw(strip-unit($canvas-size-pc), 383), 383px);
	// width: clamp(calc(380 * 0.8px), vw(strip-unit($canvas-size-pc), 380), 380px);
	// width: vw(strip-unit($canvas-size-pc), 380);

	@include mq("sp") {
		width: 100%;
	}
}

.trainer__image {
	// width: clamp(calc(380 * 0.8px), vw(strip-unit($canvas-size-pc), 380), 380px);
	width: 100%;

	// width: 100%;
	// max-width: 380px;
	height: auto;
	aspect-ratio: 380/380;
	margin: 0 auto;

	border-radius: 50%;

	@include mq("sp") {
		width: clamp(calc(180 * 0.8px), vw(strip-unit($canvas-size-sp), 180), calc(180px * 1.7));
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
}

.trainer__name {
	width: 100%;

	font-size: clamp(calc(22 * 0.8px), vw(strip-unit($canvas-size-pc), 22), 22px);
	font-weight: $font-weight-regular;
	line-height: calc(36 / 22);
	// letter-spacing: calc(/1000 * 1em);

	margin-top: 40px;
	text-align: center;

	@include mq("sp") {
		font-size: clamp(calc(18 * 0.8px), vw(strip-unit($canvas-size-sp), 18), calc(18px * 1.2));
		line-height: calc(22 / 18);

		margin-top: 20px;
	}
}

.trainer__text {
	width: 100%;
	// max-width: 384px;
	// margin: 0 auto;

	font-size: clamp(calc(16 * 0.8px), vw(strip-unit($canvas-size-pc), 16), 16px);
	font-weight: $font-weight-w3;
	line-height: calc(40 / 16);
	// letter-spacing: calc(/1000 * 1em);

	margin-top: 7px;

	@include mq("sp") {
		font-size: clamp(calc(14 * 0.8px), vw(strip-unit($canvas-size-sp), 14), calc(14px * 1.2));
		line-height: calc(26 / 14);
		letter-spacing: -0.07rem;

		margin-top: 25px;
	}
}

// .concept__item {
// 	width: 100%;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	@include mq("tab") {
// 		// flex-direction: column;
// 		align-items: flex-start;
// 	}
// 	@include mq("sp") {
// 		flex-direction: column;
// 	}
// }

// .concept__item + .concept__item {
// 	margin-top: 80px;

// 	@include mq("sp") {
// 		margin-top: 58px;
// 	}
// }

// .concept__item .concept__text {
// 	margin: 0 0 0 vw(strip-unit($canvas-size-pc), 60);

// 	@include mq("sp") {
// 		margin: 36px 0 0;
// 	}
// }
// .concept__item.u__flexbox--reverse .concept__text {
// 	margin: 0 vw(strip-unit($canvas-size-pc), 60) 0 0;
// 	// margin: 0 60px 0 0;
// 	@include mq("sp") {
// 		margin: 36px 0;
// 	}
// }

// .concept__image {
// 	width: calc(500 / 1120 * 100%);
// 	flex-shrink: 0;

// 	@include mq("sp") {
// 		width: 100%;
// 	}

// 	img {
// 		width: 100%;
// 		height: 100%;
// 		object-fit: contain;
// 	}
// }

// .conceptText__p {
// 	font-size: clamp(calc(20 * 0.8px), vw(strip-unit($canvas-size-pc), 20), 20px);
// 	font-weight: $font-weight-w3;
// 	line-height: calc(40 / 20);
// 	letter-spacing: calc(30 / 1000 * 1em);

// 	@include mq("sp") {
// 		font-size: clamp(calc(14 * 0.8px), vw(strip-unit($canvas-size-sp), 14), 14px);
// 		line-height: calc(24 / 14);
// 	}
// }

// // .conceptText__p.fontWeight-w6 {
// // 	font-weight: $font-weight-w6;
// // }

// .conceptText__p + .conceptText__p {
// 	margin-top: 40px;

// 	@include mq("sp") {
// 		margin-top: 20px;
// 	}
// }
