@use "global" as *;

// Agent ===============================
// Android
body.android {
	// input[type="checkbox"] + .consent__text::after {
	// 	top: 4px;
	// }
}

// browser ===============================
// Edge
body.edge {
	// input[type="checkbox"] + .consent__text::after {
	// 	top: 7px;
	// }
}
body.android.edge {
	// tab以下
	// input[type="checkbox"] + .consent__text::after {
	// 	@include mq("tab") {
	// 		top: 4px;
	// 	}
	// }
}

// browser ===============================
// safari
body.safari {
	a,
	button {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
		cursor: pointer;
	}

	*:focus {
		outline: none !important;
	}

	.subPageSection__about {
		&::after {
			width: min(calc(100vw - 351px - clamp(24px, vw(strip-unit($canvas-size-pc), strip-unit($padding-pc)), $padding-pc)), calc(50vw + 241px));

			@include mq("tab") {
				width: calc(100vw - 308px - clamp(24px, vw(strip-unit($canvas-size-pc), strip-unit($padding-pc)), $padding-pc));
			}
			@include mq("sp") {
				background: url(../images/img-sub-about-background-SP.png) no-repeat center center / cover;

				width: 100%;
				height: calc(100% - 128px);
				top: 128px;
			}
		}
	}
}

// firefox
body.firefox {
	.subPageSection__about {
		&::after {
			width: min(calc(100vw - 335px - clamp(24px, vw(strip-unit($canvas-size-pc), strip-unit($padding-pc)), $padding-pc)), calc(50vw + 241px));

			@include mq("tab") {
				width: calc(100vw - 292px - clamp(24px, vw(strip-unit($canvas-size-pc), strip-unit($padding-pc)), $padding-pc));
			}
			@include mq("sp") {
				background: url(../images/img-sub-about-background-SP.png) no-repeat center center / cover;

				width: 100%;
				height: calc(100% - 128px);
				top: 128px;
			}
		}
	}
}
