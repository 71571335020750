@use "global" as *;

.promotion__movie,
.streetView {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
}

.googleMap {
	position: relative;
	width: 100%;
	padding-top: 50.89%;

	@include mq("sp") {
		padding-top: 101%;
	}
}
.promotion__movie iframe,
.promotion__movie video,
.streetView iframe,
.googleMap iframe {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
