// $font-family-primary: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", "Yu Gothic", "Meiryo", sans-serif;
$font-family-primary: "Noto Sans JP", "Yu Gothic", "Meiryo", sans-serif;
$font-family-eg: "Cabin", sans-serif;
$font-family-price: "Montserrat", sans-serif;

$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

$font-weight-w3: 300;
$font-weight-w6: 600;
$font-weight-w7: 700;
