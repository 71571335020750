@use "global" as *;

.section.section__voice {
	background-color: $color-white;
}

.voice__item {
	width: 100%;
	padding: 32px clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-pc), 24), 24px);
	border: 1px solid $color-border-gray;

	@include mq("sp") {
		padding: 26px clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-sp), 24), calc(24px * 1.2)) 28px;
	}
}

.voice__item + .voice__item {
	margin-top: 44px;
}

.voice__name {
	font-size: clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-pc), 24), 24px);
	font-weight: $font-weight-regular;
	line-height: calc(24 / 36);
	// letter-spacing: calc( / 1000 * 1em);
	@include mq("sp") {
		font-size: clamp(calc(18 * 0.8px), vw(strip-unit($canvas-size-sp), 18), calc(18px * 1.2));
		line-height: calc(22 / 18);
	}
}

.voice__text {
	margin-top: 23px;

	font-size: clamp(calc(20 * 0.8px), vw(strip-unit($canvas-size-pc), 20), 20px);
	font-weight: $font-weight-regular;
	line-height: calc(30 / 20);
	letter-spacing: calc(30 / 1000 * 1em);

	@include mq("sp") {
		margin-top: 28px;

		font-size: clamp(calc(14 * 0.8px), vw(strip-unit($canvas-size-sp), 14), calc(14px * 1.2));
		line-height: calc(24 / 14);
	}
}
