@use "global" as *;

.header {
	width: 100%;
	height: $header-height-pc;

	padding: 0 clamp(24px, vw(strip-unit($canvas-size-pc), 80), 80px);

	// background-color: rgba($color-white, 0.77);
	background-color: $color-white;

	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-header;

	@include mq("tab") {
		height: $header-height-tab;
	}

	@include mq("sp") {
		height: $header-height-sp;
		padding: 0 clamp(30px, vw(strip-unit($canvas-size-sp), 30), 30px);
	}
}

.header__inner {
	max-width: $header-inner-max-width-pc;
	height: 100%;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__left {
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

.logo {
	display: block;
	// width: 257px; // ロゴ画像の横幅を指定
	// height: 68px; // ロゴ画像の縦幅を指定
	width: clamp(112px, vw(strip-unit($canvas-size-pc), 257), 257px); // ロゴ画像の横幅を指定
	height: clamp(30px, vw(strip-unit($canvas-size-pc), 68), 68px); // ロゴ画像の縦幅を指定

	transition: all 0.3s;

	// @include mq("tab") {
	// 	width: 112px;
	// 	height: 30px;
	// }

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	// background-color: $color-white;
	// line-height: 41px;
	// border: 1px solid $color-black;
	// text-align: center;
}
@media (hover: hover) and (pointer: fine) {
	.logo:hover {
		opacity: 0.6;
	}
}

.header__right {
}

.header__nav {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.headerNav__item + .headerNav__item {
	// margin-left: 80px;
	margin-left: min(vw(strip-unit($canvas-size-pc), 48), 48px);

	// @include mq("tab") {
	// 	margin-left: min(vw(strip-unit($canvas-size-tab), 18), 18px);
	// }
}

.headerNav__link {
	display: block;
	color: $color-black;
	padding: 0 5px;

	// font-size: min(rem(18), 18px);
	font-size: clamp(calc(18 * 0.8px), vw(strip-unit($canvas-size-pc), 18), 18px);
	font-family: $font-family-eg;
	font-weight: $font-weight-semibold;
	letter-spacing: calc(30 / 1000 * 1em);

	transition: all 0.3s;

	position: relative;

	&::after {
		content: "";
		width: 110%;
		height: 1px;
		background-color: $color-black;

		position: absolute;
		bottom: -3px;
		left: 50%;
		transform: translateX(-50%) scale(0);

		transition: all 0.3s;
	}

	@include mq("tab") {
		font-size: rem(14);
	}
}

@media (hover: hover) and (pointer: fine) {
	.headerNav__link:hover {
		// opacity: 0.7;
		&::after {
			transform: translateX(-50%) scale(1);
		}
	}
}

.headerNav__link:last-child {
	// margin-left: 9px;
	margin-left: min(vw(strip-unit($canvas-size-pc), 9), 9px);
	@include mq("tab") {
		margin-left: 0;
	}
}
