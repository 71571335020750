@use "global" as *;

.toTop--wrapper {
	width: min(vw(strip-unit($canvas-size-pc), 120), 120px);
	height: clamp(calc(40 * 0.8px), vw(strip-unit($canvas-size-pc), 40), 40px);

	position: fixed;
	// bottom: clamp(calc(30 * 0.8px), vw(strip-unit($canvas-size-pc), 30), 30px);
	// right: min(vw(strip-unit($canvas-size-pc), 17), 30px);
	bottom: 16px;
	right: 0;

	z-index: $z-index-floating;

	display: flex;
	align-items: flex-end;
	justify-content: center;

	@include mq("sp") {
		// width: clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-sp), 24), calc(24 * 1.2px));
		// height: clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-sp), 24), calc(24 * 1.2px));
		bottom: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
		right: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
	}
}

.toTop {
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	cursor: pointer;
	width: clamp(calc(40 * 0.8px), vw(strip-unit($canvas-size-pc), 40), 40px);
	height: clamp(calc(40 * 0.8px), vw(strip-unit($canvas-size-pc), 40), 40px);
	border-radius: 50%;
	border: 2px solid $color-background-drawer;
	background-color: $color-white;

	// position: fixed;
	// bottom: clamp(calc(30 * 0.8px), vw(strip-unit($canvas-size-pc), 30), 30px);
	// right: min(vw(strip-unit($canvas-size-pc), 17), 30px);
	// right: clamp(calc(30 * 0.8px), vw(strip-unit($canvas-size-pc), 30), 30px);

	// z-index: $z-index-floating;
	position: relative;

	@include mq("sp") {
		width: clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-sp), 24), calc(24 * 1.2px));
		height: clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-sp), 24), calc(24 * 1.2px));
		// bottom: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
		// right: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
	}

	&::after {
		content: "";
		display: block;
		width: clamp(calc(12 * 0.8px), vw(strip-unit($canvas-size-pc), 12), 12px);
		height: clamp(calc(12 * 0.8px), vw(strip-unit($canvas-size-pc), 12), 12px);

		border-top: 2px solid $color-background-drawer;
		border-right: 2px solid $color-background-drawer;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -32%) rotate(-45deg);

		@include mq("sp") {
			width: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
			height: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
		}
	}
}

.toTop.is-visible {
	opacity: 1;
	visibility: visible;
}

@media (hover: hover) and (pointer: fine) {
	.toTop:hover {
		opacity: 0.6;
	}
}
