@use "global" as *;

html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-size: 16px;
	// font-size: clamp(16px, vw(strip-unit($canvas-size-pc), 16), calc(16px * 1.5));

	// @include mq("tab") {
	// 	font-size: vw(strip-unit($canvas-size-tab), 16);
	// }

	@include mq("tab") {
		// font-size: clamp(14px, vw(strip-unit($canvas-size-tab), 16), 16px);
		font-size: 14px;
	}

	@include mq("sp") {
		// font-size: clamp(14px, vw(strip-unit($canvas-size-sp), 16), 16px);
		font-size: 12px;
	}
}
* {
	font-family: $font-family-primary;
	font-weight: $font-weight-regular;
	color: $color-black;
	letter-spacing: 0;
}

body {
	width: 100%;
	background-color: $color-white;
	// overflow-x: hidden;
}

a,
button {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	cursor: pointer;
}

*:focus {
	outline: none;
}

*:focus-visible {
	outline: $color-primary auto 1px;
}

// 電話番号リンク：PC無効
@include mq("over-sp") {
	a[href*="tel:"] {
		pointer-events: none;
		cursor: default;
		text-decoration: none;
	}
}
