@use "global" as *;

.section__program {
	background-color: $color-primary;
}

.program__nav {
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	@include mq("sp") {
		display: block;
	}
}

.program__navItem {
	width: 33.3%;
	position: relative;
	@include mq("sp") {
		width: 100%;
	}
}

.program__navItem + .program__navItem {
	&::before {
		content: "";
		display: block;
		width: 1px;
		height: 85px;
		background-color: $color-border-gray;

		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);

		z-index: calc($z-index-default + 1);

		@include mq("sp") {
			width: 100%;
			height: 1px;
			top: 0;
		}
	}
}

.program__navLink {
	display: block;
	width: 100%;

	background-color: $color-offWhite;

	padding: 37px 0 36px;
	text-align: center;

	transition: all 0.3s;

	@include mq("sp") {
		padding: 41px 0 40px;
	}
}

@media (hover: hover) and (pointer: fine) {
	.program__navLink:hover {
		opacity: 0.6;
		// filter: brightness(0.8);
	}
	.program__navLink:hover .program__navLinkText::after {
		animation: arrowMove 1.2s ease-in-out infinite;
		// transform: scale(2);
	}
}
/*下からの距離が変化して全体が下→上→下に動く*/
@keyframes arrowMove {
	0% {
		transform: translateY(-50%);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-50%);
	}
}

.program__navLinkText {
	font-family: $font-family-eg;
	font-size: clamp(calc(22 * 0.8px), vw(strip-unit($canvas-size-pc), 22), 22px);
	font-weight: $font-weight-regular;
	// line-height: calc(/);
	// line-height: 100px;
	letter-spacing: calc(60 / 1000 * 1em);

	position: relative;

	@include mq("sp") {
		font-size: clamp(calc(18 * 0.8px), vw(strip-unit($canvas-size-sp), 18), calc(18 * 1.2px));
	}

	&::after {
		content: "";
		display: block;
		width: clamp(calc(13 * 0.8px), vw(strip-unit($canvas-size-pc), 13), 13px);
		height: clamp(calc(9 * 0.8px), vw(strip-unit($canvas-size-pc), 9), 9px);
		background: url(../images/img-icon-arrow-down.svg) no-repeat center center / contain;

		position: absolute;
		top: 50%;
		right: clamp(-24px, vw(strip-unit($canvas-size-pc), -24), calc(-24 * 0.8px));
		transform: translateY(-50%);

		@include mq("sp") {
			width: clamp(calc(13 * 0.8px), vw(strip-unit($canvas-size-sp), 13), calc(13 * 1.2px));
			height: clamp(calc(9 * 0.8px), vw(strip-unit($canvas-size-sp), 9), calc(9 * 1.2px));
			right: clamp(calc(-24 * 1.2px), vw(strip-unit($canvas-size-sp), -20), calc(-24 * 0.8px));
		}
	}
}

.program__items {
	padding-top: 80px;

	@include mq("sp") {
		padding-top: 19px;
	}
}
.program__item:first-of-type {
	@include mq("sp") {
		padding-top: 24px;
	}
}
.program__item + .program__item {
	padding-top: 80px;

	@include mq("sp") {
		padding-top: 48px;
		// padding-top: 24px;
	}
}

.program__title {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.program__title--eg {
	font-family: $font-family-eg;
	font-size: clamp(calc(32 * 0.8px), vw(strip-unit($canvas-size-pc), 32), 32px);
	font-weight: $font-weight-medium;
	line-height: calc(39 / 32);
	letter-spacing: calc(60 / 1000 * 1em);

	position: relative;

	@include mq("sp") {
		font-size: clamp(calc(18 * 0.8px), vw(strip-unit($canvas-size-sp), 18), calc(18 * 1.2px));
	}

	&::after {
		content: "";
		display: block;
		// width: 100%;
		width: clamp(calc(195 * 0.8px), vw(strip-unit($canvas-size-pc), 195), 195px);
		height: 3px;
		background-color: $color-border-black;

		position: absolute;
		bottom: -13px;
		left: 0;

		@include mq("sp") {
			width: clamp(calc(107 * 0.8px), vw(strip-unit($canvas-size-sp), 107), calc(107 * 1.2px));

			bottom: -12px;
		}
	}
}
.program__title--jp {
	font-size: clamp(calc(20 * 0.8px), vw(strip-unit($canvas-size-pc), 20), 20px);
	font-weight: $font-weight-regular;
	line-height: calc(40 / 20);
	letter-spacing: calc(30 / 1000 * 1em);

	margin-left: clamp(calc(20 * 0.8px), vw(strip-unit($canvas-size-pc), 20), 20px);

	@include mq("sp") {
		font-size: clamp(calc(18 * 0.8px), vw(strip-unit($canvas-size-sp), 18), calc(18 * 1.2px));
		margin-left: clamp(calc(12 * 0.8px), vw(strip-unit($canvas-size-sp), 12), calc(12 * 1.2px));
	}
}

.program__body {
	margin-top: 53px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	@include mq("sp") {
		display: block;
		margin-top: 22px;
	}
}

.program__image {
	width: calc(465 / 1120 * 100%);
	flex-shrink: 0;

	@include mq("sp") {
		width: 100%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.program__text {
	height: 100%;
	margin-left: clamp(calc(94 * 0.8px), vw(strip-unit($canvas-size-pc), 94), 94px);
	border-top: 1px solid $color-border-black;
	border-bottom: 1px solid $color-border-black;

	padding: 44px 0 22px;

	@include mq("sp") {
		margin-top: 24px;
		// margin-top: 48px;
		margin-left: 0;
		padding: 21px 0 13px;
	}
}
.program__time {
	// font-family: $font-family-eg;
	font-size: clamp(calc(22 * 0.8px), vw(strip-unit($canvas-size-pc), 22), 22px);
	font-weight: $font-weight-regular;
	line-height: calc(27 / 22);
	letter-spacing: calc(60 / 1000 * 1em);

	display: flex;
	align-items: center;
	justify-content: flex-start;

	@include mq("sp") {
		font-size: clamp(calc(16 * 0.8px), vw(strip-unit($canvas-size-sp), 16), calc(16 * 1.2px));
	}

	&::before {
		content: "";
		display: block;
		width: clamp(calc(32 * 0.8px), vw(strip-unit($canvas-size-pc), 32), 32px);
		height: clamp(calc(32 * 0.8px), vw(strip-unit($canvas-size-pc), 32), 32px);
		background: url(../images/img-icon-clock.svg) no-repeat center center / contain;

		margin-right: clamp(calc(22 * 0.8px), vw(strip-unit($canvas-size-pc), 22), 22px);

		@include mq("sp") {
			width: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-sp), 23), calc(23 * 1.2px));
			height: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-sp), 23), calc(23 * 1.2px));
			margin-right: clamp(calc(10 * 0.8px), vw(strip-unit($canvas-size-sp), 10), calc(10 * 1.2px));
		}
	}
}
.program__price {
	margin-top: 20px;
	font-family: $font-family-price;
	font-size: clamp(calc(40 * 0.8px), vw(strip-unit($canvas-size-pc), 40), 40px);
	font-weight: $font-weight-semibold;
	line-height: calc(49 / 40);
	// letter-spacing: calc( / 1000 * 1em);

	display: flex;
	align-items: center;
	justify-content: flex-start;

	@include mq("sp") {
		font-size: clamp(calc(26 * 0.8px), vw(strip-unit($canvas-size-sp), 26), calc(26 * 1.2px));
		margin-top: 15px;
	}
}
.program__price--note {
	font-size: clamp(calc(20 * 0.7px), vw(strip-unit($canvas-size-pc), 20), 20px);
	font-weight: $font-weight-regular;
	line-height: calc(50 / 20);
	letter-spacing: calc(30 / 1000 * 1em);
	margin-left: clamp(calc(20 * 0.8px), vw(strip-unit($canvas-size-pc), 20), 20px);

	@include mq("sp") {
		font-size: clamp(calc(14 * 0.8px), vw(strip-unit($canvas-size-sp), 14), calc(14 * 1.2px));
		line-height: calc(24 / 14);

		margin-top: 17px;
	}
}

.icon__yen {
	font-family: $font-family-price;
	font-size: clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-pc), 24), 24px);
	font-weight: $font-weight-semibold;
	line-height: calc(29 / 24);
	// letter-spacing: calc( / 1000 * 1em);
	margin-right: clamp(calc(12 * 0.8px), vw(strip-unit($canvas-size-pc), 12), 12px);
	@include mq("sp") {
		font-size: clamp(calc(16 * 0.8px), vw(strip-unit($canvas-size-sp), 16), calc(16 * 1.2px));
		margin-right: clamp(calc(10 * 0.8px), vw(strip-unit($canvas-size-sp), 10), calc(10 * 1.2px));
	}
}
.program__description {
	margin-top: 32px;

	font-size: clamp(calc(20 * 0.8px), vw(strip-unit($canvas-size-pc), 20), 20px);
	font-weight: $font-weight-regular;
	line-height: calc(50 / 20);
	letter-spacing: calc(30 / 1000 * 1em);

	@include mq("sp") {
		font-size: clamp(calc(14 * 0.8px), vw(strip-unit($canvas-size-sp), 14), calc(14 * 1.2px));
		line-height: calc(24 / 14);

		margin-top: 17px;
	}
}
