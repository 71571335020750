@use "global" as *;

.section.section__access {
	background-color: $color-secondary;
}
.section__access .section__body {
	margin-top: 92px;

	@include mq("sp") {
		margin-top: 35px;
	}
}

.googleMap--rapper {
	width: 100%;
	max-width: $inner-max-width-pc;
	margin-top: 51px;

	@include mq("sp") {
		margin-top: 38px;
	}
}

.access__items {
	margin-top: 60px;
	@include mq("sp") {
		margin-top: 42px;
	}
}

.access__locationName,
.access__item {
	position: relative;
	&::after {
		content: "";
		width: 3px;
		height: 30px;
		background-color: $color-deco-bar-black;

		position: absolute;
		top: -5px;
		left: 0;
	}
}
.access__locationName {
	&::after {
		@include mq("sp") {
			top: 0;
		}
	}
}

.access__item {
	&::before {
		content: "";
		width: 3px;
		height: 100%;
		background-color: $color-deco-bar-gray;

		position: absolute;
		top: -5px;
		left: 0;
	}
}

.access__item + .access__item {
	margin-top: 56px;

	@include mq("sp") {
		margin-top: 42px;
	}
}

.access__locationName,
.access__title,
.access__text {
	font-size: clamp(calc(20 * 0.8px), vw(strip-unit($canvas-size-pc), 20), 20px);
	font-weight: $font-weight-regular;
	letter-spacing: calc(30 / 1000 * 1em);

	padding-left: clamp(calc(40 * 0.8px), vw(strip-unit($canvas-size-pc), 40), 40px);
}

.access__locationName,
.access__title {
	line-height: calc(40 / 20);

	@include mq("sp") {
		font-size: clamp(calc(18 * 0.8px), vw(strip-unit($canvas-size-sp), 18), calc(18 * 1.2px));
	}
}
.access__text {
	line-height: calc(26 / 20);
	margin-top: 11px;

	@include mq("sp") {
		margin-top: 0;
		font-size: clamp(calc(14 * 0.8px), vw(strip-unit($canvas-size-sp), 14), calc(14 * 1.2px));
		line-height: calc(24 / 14);
	}
}
