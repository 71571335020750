$color-primary: #a0bfe0;
$color-secondary: #dbdfea;
$color-accent: #8294c4;
$color-offWhite: #f6f5f4;

$color-white: #ffffff;
$color-black: #333333;

$color-border-gray: #707070;
$color-border-black: #333333;

$color-tab-background-gray: #eee;

$color-deco-bar-black: #000000;
$color-deco-bar-gray: #e2e2e2;

$color-background-drawer: #d1d1d1;
$color-background-footer: #3b4042;

// background
// $color-background-blue: #a0bfe0;
// $color-background-gray: #acacac;
// $color-background-blueGray: #dbdfea;

// news
// $color-category-news: #eab760;
// $color-category-release: #016aa5;

// contact
// $color-required: #ff4536;
// $color-optional: #c1bbb1;

// $color-placeholder: #9b9b9b;
// $color-gray: #cecdcd;
// $color-background-gray: #f4f4f4;
