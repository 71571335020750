@use "global" as *;

.footer {
	background-color: $color-background-footer;
}

.footer__inner.section__inner {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-direction: row;

	@include mq("sp") {
		display: block;
		padding-top: 51px;
	}
}

.footer__text--big,
.footer__text--small,
.footer__tel,
.footer__copyRight {
	color: $color-white;
}

.footer__text--big {
	font-family: $font-family-eg;
	font-size: clamp(calc(90 * 0.8px), vw(strip-unit($canvas-size-pc), 90), 90px);
	font-weight: $font-weight-regular;
	line-height: calc(110 / 90);
	letter-spacing: calc(60 / 1000 * 1em);

	@include mq("sp") {
		font-size: clamp(calc(50 * 0.8px), vw(strip-unit($canvas-size-sp), 50), calc(50 * 1.2px));
		line-height: calc(61 / 50);
		text-align: center;
	}
}

.footer__text--small {
	font-size: clamp(calc(20 * 0.8px), vw(strip-unit($canvas-size-pc), 20), 20px);
	font-weight: $font-weight-regular;
	line-height: calc(26 / 20);
	letter-spacing: calc(30 / 1000 * 1em);

	margin-top: 14px;

	@include mq("sp") {
		font-size: clamp(calc(14 * 0.8px), vw(strip-unit($canvas-size-sp), 14), calc(14 * 1.2px));
		line-height: calc(24 / 14);
		text-align: center;
	}
}

.contact__link {
	transition: all 0.3s;
}
@media (hover: hover) and (pointer: fine) {
	.contact__link:hover {
		opacity: 0.6;
	}
}

.footer__snsIcons {
	display: flex;
	align-items: center;
	justify-content: center;
	@include mq("sp") {
		display: block;
	}
}

.footer__snsIcon--wrapper {
	@include mq("sp") {
		margin: 0 auto;
		margin-top: 30px;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.footer__snsIcon--wrapper + .footer__snsIcon--wrapper {
	margin-left: clamp(calc(12 * 0.8px), vw(strip-unit($canvas-size-pc), 12), 12px);
	@include mq("sp") {
		margin-left: auto;
	}
}

.footer__snsLink {
	transition: all 0.3s;
}
@media (hover: hover) and (pointer: fine) {
	.footer__snsLink:hover {
		opacity: 0.6;
	}
}

.footer__snsIcon--instagram {
	width: clamp(calc(66 * 0.8px), vw(strip-unit($canvas-size-pc), 66), 66px);
	height: clamp(calc(66 * 0.8px), vw(strip-unit($canvas-size-pc), 66), 66px);
	@include mq("sp") {
		width: clamp(calc(45 * 0.8px), vw(strip-unit($canvas-size-sp), 45), calc(45 * 1.2px));
		height: clamp(calc(45 * 0.8px), vw(strip-unit($canvas-size-sp), 45), calc(45 * 1.2px));
	}
}
.footer__snsIcon--hotpepperbeauty {
	width: clamp(calc(152 * 0.8px), vw(strip-unit($canvas-size-pc), 152), 152px);
	height: clamp(calc(51 * 0.8px), vw(strip-unit($canvas-size-pc), 51), 51px);
	@include mq("sp") {
		width: clamp(calc(152 * 0.8px), vw(strip-unit($canvas-size-sp), 152), calc(152 * 1.2px));
		height: clamp(calc(51 * 0.8px), vw(strip-unit($canvas-size-sp), 51), calc(51 * 1.2px));
	}
}

.footer__tel--wrapper {
	// margin-top: 9px; //font-size26
	margin-top: 16px; //font-size30

	text-align: center;
	// font-family: $font-family-eg;
	// font-family: $font-family-price;

	position: relative;
	left: -5px; // cabin

	@include mq("sp") {
		margin: 0 auto;
		width: fit-content;
		margin-top: 26px;
	}
}
.footer__tel {
	display: block;
	// font-family: $font-family-eg;
	// font-family: $font-family-price;
	// font-size: clamp(calc(26 * 0.8px), vw(strip-unit($canvas-size-pc), 26), 26px);
	font-size: clamp(calc(30 * 0.8px), vw(strip-unit($canvas-size-pc), 30), 30px);
	font-weight: $font-weight-regular;
	// line-height: calc(34 / 26);
	line-height: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-pc), 23), 23px);

	letter-spacing: calc(40 / 1000 * 1em);
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	@include mq("sp") {
		font-size: clamp(calc(26 * 0.8px), vw(strip-unit($canvas-size-sp), 26), calc(26 * 1.2px));
		// line-height: calc(34 / 26);
		line-height: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-sp), 23), calc(23 * 1.2px));
	}

	// &::before {
	// 	content: "";
	// 	display: block;
	// 	width: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-pc), 23), 23px);
	// 	height: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-pc), 23), 23px);
	// 	background: url(../images/img-icon-tel.svg) no-repeat center center / contain;

	// 	position: absolute;
	// 	top: 50%;
	// 	left: clamp(-28px, vw(strip-unit($canvas-size-pc), -28), calc(-28 * 0.8px));
	// 	transform: translateY(-50%);

	// 	@include mq("sp") {
	// 		width: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-sp), 23), calc(23 * 1.2px));
	// 		height: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-sp), 23), calc(23 * 1.2px));
	// 		left: clamp(calc(-28 * 1.2px), vw(strip-unit($canvas-size-sp), -28), calc(-28 * 0.8px));
	// 	}
	// }
}
.footer__telIcon--wrapper {
	display: block;
	width: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-pc), 23), 23px);
	height: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-pc), 23), 23px);
	margin-right: clamp(calc(4 * 0.8px), vw(strip-unit($canvas-size-pc), 4), 4px);

	position: relative;
	top: 2px; //Noto sans Jp
	// top: 0px; // Cabin, Montserrat

	@include mq("sp") {
		width: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-sp), 23), calc(23 * 1.2px));
		height: clamp(calc(23 * 0.8px), vw(strip-unit($canvas-size-sp), 23), calc(23 * 1.2px));
		margin-right: clamp(calc(4 * 0.8px), vw(strip-unit($canvas-size-sp), 4), calc(4 * 1.2px));
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.footer__copyRight {
	width: 100%;
	max-width: $header-inner-max-width-pc;
	margin: 0 auto;
	text-align: right;
	padding: 74px 0 56px;

	font-family: $font-family-eg;
	font-size: clamp(calc(12 * 0.8px), vw(strip-unit($canvas-size-pc), 12), 12px);
	font-weight: $font-weight-regular;
	line-height: calc(18 / 12);
	// letter-spacing: calc(/1000 * 1em);

	@include mq("sp") {
		font-size: clamp(calc(12 * 0.8px), vw(strip-unit($canvas-size-sp), 12), calc(12 * 1.2px));
		// line-height: calc(/);
		// letter-spacing: calc(/1000 * 1em);
		text-align: center;
		padding: 11px 0 28px;
	}
}
