@use "global" as *;

.section.section__concept {
	background-color: $color-primary;
}

.concept__item {
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	@include mq("tab") {
		// flex-direction: column;
		align-items: flex-start;
	}
	@include mq("sp") {
		flex-direction: column;
	}
}

.concept__item + .concept__item {
	margin-top: 80px;

	@include mq("sp") {
		margin-top: 58px;
	}
}

.concept__item .concept__text {
	margin: 0 0 0 vw(strip-unit($canvas-size-pc), 60);

	@include mq("sp") {
		margin: 36px 0 0;
	}
}
.concept__item.u__flexbox--reverse .concept__text {
	margin: 0 vw(strip-unit($canvas-size-pc), 60) 0 0;
	// margin: 0 60px 0 0;
	@include mq("sp") {
		margin: 36px 0;
	}
}

.concept__image {
	width: calc(500 / 1120 * 100%);
	flex-shrink: 0;

	@include mq("sp") {
		width: 100%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.conceptText__p {
	font-size: clamp(calc(20 * 0.8px), vw(strip-unit($canvas-size-pc), 20), 20px);
	font-weight: $font-weight-w3;
	line-height: calc(40 / 20);
	letter-spacing: calc(30 / 1000 * 1em);

	@include mq("sp") {
		font-size: clamp(calc(14 * 0.8px), vw(strip-unit($canvas-size-sp), 14), calc(14px * 1.2));
		line-height: calc(24 / 14);
	}
}

// .conceptText__p.fontWeight-w6 {
// 	font-weight: $font-weight-w6;
// }

.conceptText__p + .conceptText__p {
	margin-top: 40px;

	@include mq("sp") {
		margin-top: 24px;
	}
}
