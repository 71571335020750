$z-index-modal: 100;
$z-index-drawer: 40;
$z-index-drawer: 200;
$z-index-floating: 30;

$z-index-modal: 30;
$z-index-header: 20;
$z-index-footer: 10;
$z-index-default: 0;
$z-index-background: -10;
