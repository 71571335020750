$padding-pc: 120px;
$padding-tab: 60px;
$padding-sp: 30px;

$header-height-pc: 112px;
$header-height-tab: 90px;
$header-height-sp: 70px;
$header-inner-max-width-pc: 1450px;
// $header-inner-max-width-sp: 1450px;

// $header-height-sp: 68px;

$canvas-size-pc: 1920px;
$canvas-size-tab: 768px;
$canvas-size-sp: 375px;

$inner-max-width-pc: 1120px;
$youtube-max-width-pc: 1024px; // Youtube横幅最大値
$streetView-max-width-pc: 1024px; // Youtube横幅最大値
// $inner-max-width-sp: 343px;

// $margin-top-section-pc: 130px;
// $margin-top-section-sp: 60px;
$padding-top-section-inner-pc: 130px;
$padding-top-section-inner-sp: 60px;
$padding-bottom-section-inner-pc: 130px;
$padding-bottom-section-inner-sp: 60px;

$padding-top-section-body-pc: 70px;
$padding-top-section-body-sp: 40px;

// 後ほど削除
$margin-title-sp: 80px;
$margin-top-content-pc: 40px;
$margin-top-content-sp: 40px;
$margin-top-img-pc: 40px;
$margin-top-img-sp-wide: 40px;
$margin-top-img-sp-narrow: 20px;

// $padding-pc: clamp(24px, vw(strip-unit($canvas-size-pc), 80), 80px);
// $padding-tab: clamp(16px, vw(strip-unit($canvas-size-pc), 24), 24px);
// $padding-sp: clamp(8px, vw(strip-unit($canvas-size-pc), 16), 16px);
