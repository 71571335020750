@charset "UTF-8";

@use "../global/breakpoint" as *;

// ================================================================
// ブレークポイント変数
// ================================================================
//マップ型変数を定義
$breakpoints: (
	// "sp": 'screen and (max-width: 567px)',
	// "sp-m": 'screen and (min-width: 568px) and (max-width: 767px)',
	// "tb": "screen and (min-width: 768px) and (max-width: 1024px)",
	// "pc-m": "screen and (min-width: 1025px) and (max-width: 1840px)",
	// "pc-l": "screen and (min-width: 1841px)",
	// // モバイル（スマホ＆タブレット）の表示
	// "mobile": "screen and (max-width: 767px)",
	// PCの表示
	"pc": "screen and (min-width: #{ $pc })",
	// タブレットの表示
	"tab": "screen and (max-width: #{ $tab })",
	// "tab": "screen and (max-width: #{ $tab })",
	// タブレットsmallの表示
	// "tab": "screen and (max-width: #{ $tab })",
	// スマートフォンの表示
	"sp": "screen and (max-width: #{ $sp })",
	// "sp": "screen and (max-width: #{ $sp })",
	// スマートフォンsmallの表示(iPhoneSE向け)
	// "sp": "screen and (max-width: #{ $sp })",
	// タブレットsmallより大きい場合の表示
	// "over-tab": "screen and (min-width: #{ $tab  + 1px})"
	// スマートフォンより大きい場合の表示
	"over-sp": "screen and (min-width: #{ $sp  + 1px})"
) !default;

// ================================================================
// mixin
// ================================================================
// ----------------------------------------------------------------
//メディアクエリ用mixin
// ----------------------------------------------------------------
@mixin mq($breakpoint: pc) {
	//map-get(マップ型変数, キー)で値を取得
	@media #{map-get($breakpoints, $breakpoint)} {
		//この中をカスタムできる
		@content;
	}
}
