@use "global" as *;

.btn {
	cursor: pointer;
}

.btn--wrapper {
	width: 100%;
	max-width: $inner-max-width-pc;
	padding: 130px 0;
	margin: 0 auto;

	@include mq("sp") {
		padding: 56px min(vw(strip-unit($canvas-size-sp), strip-unit($padding-sp)), $padding-sp) 71px;
		// padding: 0;
	}
}

.btn__reservation {
	display: block;
	width: 100%;
	height: clamp(calc(215 * 0.8px), vw(strip-unit($canvas-size-pc), 215), 215px);
	background-color: $color-accent;
	// border: 1px solid $color-border-gray;

	color: $color-white;
	font-size: clamp(calc(35 * 0.8px), vw(strip-unit($canvas-size-pc), 35), 35px);
	font-weight: $font-weight-regular;
	line-height: clamp(calc(215 * 0.8px), vw(strip-unit($canvas-size-pc), 215), 215px);
	letter-spacing: calc(30 / 1000 * 1em);
	text-align: center;

	margin: 0 auto;

	transition: all 0.3s;

	display: flex;
	align-items: center;
	justify-content: center;

	@include mq("sp") {
		height: clamp(calc(80 * 0.8px), vw(strip-unit($canvas-size-sp), 80), calc(80 * 1.2px));
		line-height: clamp(calc(80 * 0.8px), vw(strip-unit($canvas-size-sp), 80), calc(80 * 1.2px));

		font-size: clamp(calc(16 * 0.8px), vw(strip-unit($canvas-size-sp), 16), calc(16 * 1.2px));
		// line-height: clamp(calc(215 * 0.8px), vw(strip-unit($canvas-size-pc), 215), 215px);
		line-height: clamp(calc(80 * 0.8px), vw(strip-unit($canvas-size-sp), 80), calc(80 * 1.2px));
		letter-spacing: calc(30 / 1000 * 1em);
	}

	&::after {
		content: "";
		display: block;
		width: clamp(calc(36 * 0.8px), vw(strip-unit($canvas-size-pc), 36), 36px);
		height: clamp(calc(14 * 0.8px), vw(strip-unit($canvas-size-pc), 14), 14px);
		// transform: translateY(5px);
		background: url(../images/img-icon-arrow-reservation-right.svg) no-repeat center center / contain;
		margin-left: clamp(calc(33 * 0.8px), vw(strip-unit($canvas-size-pc), 33), 33px);

		// transition: transform 0.4s;

		@include mq("sp") {
			width: clamp(calc(18 * 0.8px), vw(strip-unit($canvas-size-sp), 18), calc(18 * 1.2px));
			height: clamp(calc(7 * 0.8px), vw(strip-unit($canvas-size-sp), 7), calc(7 * 1.2px));
			margin-left: clamp(calc(13 * 0.8px), vw(strip-unit($canvas-size-sp), 13), calc(13 * 1.2px));
		}
	}

	// @include mq("sp") {
	// 	width: 200px;
	// 	height: 48px;
	// 	font-size: 14px;

	// 	line-height: 48px;
	// }
}

@media (hover: hover) and (pointer: fine) {
	.btn__reservation:hover {
		opacity: 0.6;
		background-color: $color-accent;

		&::after {
			// transform: translateY(5px) translateX(10px);
			// transform: translateX(10px);
			animation: reservationArrowMove 1.2s ease-in-out infinite;
		}
	}
}

@keyframes reservationArrowMove {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(10px);
	}
	100% {
		transform: translateX(0px);
	}
}
