@use "global" as *;

.floatingBar {
	// width: clamp(calc(120 * 0.8px), vw(strip-unit($canvas-size-pc), 120), 120px);
	width: min(vw(strip-unit($canvas-size-pc), 120), 120px);
	height: 100dvh;
	height: 100vh;

	background-color: $color-white;
	opacity: 0.9;

	position: fixed;
	top: 0;
	right: 0;
	z-index: calc($z-index-header - 1);

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@include mq("sp") {
		display: none;
	}
}

.floatingBar__snsIcon--wrapper {
	transition: all 0.3s;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

@media (hover: hover) and (pointer: fine) {
	.floatingBar__snsIcon--wrapper:hover {
		opacity: 0.6;
		animation: iconMove 1.2s ease-in-out infinite;
	}
}

// @media (hover: hover) and (pointer: fine) {
// 	.program__navLink:hover {
// 		opacity: 0.6;
// 		// filter: brightness(0.8);
// 	}
// 	.program__navLink:hover .program__navLinkText::after {
// 		animation: arrowMove 1.2s ease-in-out infinite;
// 		// transform: scale(2);
// 	}
// }

@keyframes iconMove {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

.floatingBar__snsIcon--wrapper + .floatingBar__snsIcon--wrapper {
	margin-top: 45px;
}

.floatingBar__snsIcon--instagram {
	width: clamp(calc(44 * 0.5px), vw(strip-unit($canvas-size-pc), 44), 44px);
	height: clamp(calc(44 * 0.5px), vw(strip-unit($canvas-size-pc), 44), 44px);
	// width: clamp(calc(44 * 0.8px), vw(strip-unit($canvas-size-pc), 44), 44px);
	// height: clamp(calc(44 * 0.8px), vw(strip-unit($canvas-size-pc), 44), 44px);
}
.floatingBar__snsIcon--tiktok {
	width: clamp(calc(39 * 0.5px), vw(strip-unit($canvas-size-pc), 39), 39px);
	height: clamp(calc(44 * 0.5px), vw(strip-unit($canvas-size-pc), 44), 44px);
}

// .toTop {
// 	opacity: 0;
// 	visibility: hidden;
// 	transition: all 0.3s;

// 	cursor: pointer;
// 	width: clamp(calc(40 * 0.8px), vw(strip-unit($canvas-size-pc), 40), 40px);
// 	height: clamp(calc(40 * 0.8px), vw(strip-unit($canvas-size-pc), 40), 40px);
// 	border-radius: 50%;
// 	border: 2px solid $color-background-drawer;
// 	background-color: $color-white;

// 	position: fixed;
// 	bottom: clamp(calc(30 * 0.8px), vw(strip-unit($canvas-size-pc), 30), 30px);
// 	right: clamp(calc(30 * 0.8px), vw(strip-unit($canvas-size-pc), 30), 30px);

// 	z-index: $z-index-floating;

// 	@include mq("sp") {
// 		width: clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-sp), 24), calc(24 * 1.2px));
// 		height: clamp(calc(24 * 0.8px), vw(strip-unit($canvas-size-sp), 24), calc(24 * 1.2px));
// 		bottom: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
// 		right: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
// 	}

// 	&::after {
// 		content: "";
// 		display: block;
// 		width: clamp(calc(12 * 0.8px), vw(strip-unit($canvas-size-pc), 12), 12px);
// 		height: clamp(calc(12 * 0.8px), vw(strip-unit($canvas-size-pc), 12), 12px);

// 		border-top: 2px solid $color-background-drawer;
// 		border-right: 2px solid $color-background-drawer;

// 		position: absolute;
// 		top: 50%;
// 		left: 50%;
// 		transform: translate(-50%, -32%) rotate(-45deg);

// 		@include mq("sp") {
// 			width: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
// 			height: clamp(calc(8 * 0.8px), vw(strip-unit($canvas-size-sp), 8), calc(8 * 1.2px));
// 		}
// 	}
// }

// .toTop.is-visible {
// 	opacity: 1;
// 	visibility: visible;
// }

// @media (hover: hover) and (pointer: fine) {
// 	.toTop:hover {
// 		opacity: 0.6;
// 	}
// }
