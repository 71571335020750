@use "global" as *;

.fv {
	width: 100%;
	height: calc(100vh - $header-height-pc);
	height: calc(100dvh - $header-height-pc);
	aspect-ratio: 1920/968;
	max-height: 968px;
	min-height: 560px;
	margin-top: $header-height-pc;

	// background: url(../images/img-fv-PC.png) no-repeat center center / cover;
	// background: url(../images/img-fv-PC.webp) no-repeat center center / cover;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	@include mq("tab") {
		height: calc(100vh - $header-height-tab);
		height: calc(100dvh - $header-height-tab);

		margin-top: $header-height-tab;
	}

	@include mq("sp") {
		width: 100%;
		height: auto;
		aspect-ratio: 375/400;

		min-height: initial;
		height: calc(100vh - $header-height-sp);
		height: calc(100dvh - $header-height-sp);
		max-width: 100%;
		// background: url(../images/img-fv-SP.png) no-repeat center center / cover;
		// background: url(../images/img-fv-SP.webp) no-repeat center center / cover;
		margin-top: $header-height-sp;
	}
}

.fv__background--wrapper {
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.fv__logo--wrapper {
	width: clamp(calc(706 * 0.8px), vw(strip-unit($canvas-size-pc), 706), 706px);
	height: auto;
	aspect-ratio: 706/188;

	position: absolute;

	@include mq("sp") {
		// width: clamp(calc(300 * 0.8px), vw(strip-unit($canvas-size-sp), 300), calc(300 * 1.2px));
		width: max(calc(315 * 0.8px), vw(strip-unit($canvas-size-sp), 315));
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

// .fv__title {
// 	font-family: $font-family-eg;
// 	font-size: clamp(56px, vw(strip-unit($canvas-size-pc), 80), 80px);
// 	font-weight: $font-weight-regular;
// 	line-height: calc(120 / 80);
// 	// letter-spacing: calc(/1000 * 1em);
// 	text-align: center;

// 	color: $color-white;

// 	transform: translateY(10px);

// 	@include mq("sp") {
// 		font-size: 56px;
// 	}
// }
