@use "global" as *;

.section__promotion {
	background-color: $color-offWhite;
}

.section.section__promotion {
	@include mq("sp") {
		// padding: 0 min(vw(strip-unit($canvas-size-sp), 54), 54px);
		padding: 0;
	}
}

.section.section__promotion .section__inner {
	width: calc(1024 / 1120 * 100%);
	@include mq("sp") {
		width: 100%;
		padding: 46px 0;
	}
}

.promotion__movie--rapper {
	width: 100%;
	max-width: $youtube-max-width-pc;
}
