html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-size: 16px;
}

* {
	font-family: "Noto Sans JP", "Yu Gothic", "Meiryo", sans-serif;
	font-weight: 400;
	color: #333333;
	letter-spacing: 0;
}

body {
	width: 100%;
	background-color: #ffffff;
}

a,
button {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	cursor: pointer;
}

*:focus {
	outline: none;
}

*:focus-visible {
	outline: #a0bfe0 auto 1px;
}

body.safari a,
body.safari button {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	cursor: pointer;
}

body.safari *:focus {
	outline: none !important;
}

body.safari .subPageSection__about::after {
	width: min(100vw - 351px - clamp(24px, 6.25vw, 120px), 50vw + 241px);
}

body.firefox .subPageSection__about::after {
	width: min(100vw - 335px - clamp(24px, 6.25vw, 120px), 50vw + 241px);
}

.btn {
	cursor: pointer;
}

.btn--wrapper {
	width: 100%;
	max-width: 1120px;
	padding: 130px 0;
	margin: 0 auto;
}

.btn__reservation {
	display: block;
	width: 100%;
	height: clamp(172px, 11.1979166667vw, 215px);
	background-color: #8294c4;
	color: #ffffff;
	font-size: clamp(28px, 1.8229166667vw, 35px);
	font-weight: 400;
	line-height: clamp(172px, 11.1979166667vw, 215px);
	letter-spacing: 0.03em;
	text-align: center;
	margin: 0 auto;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn__reservation::after {
	content: "";
	display: block;
	width: clamp(28.8px, 1.875vw, 36px);
	height: clamp(11.2px, 0.7291666667vw, 14px);
	background: url(../images/img-icon-arrow-reservation-right.svg) no-repeat center center/contain;
	margin-left: clamp(26.4px, 1.71875vw, 33px);
}

.drawer__inner {
	position: relative;
	width: 100%;
	padding-left: 2.2916666667vw;
}

.header__drawer .drawer {
	display: block;
	width: 30px;
	height: 24px;
	margin-left: auto;
	position: fixed;
	z-index: 200;
	border: none;
	background-color: transparent;
	right: min(8vw, 30px);
	top: 23px;
}

.header__drawer .drawer__lines {
	display: block;
	width: 30px;
	height: 1.5px;
	background-color: #333333;
	transition: all 0.3s;
}

.header__drawer .drawer__lines::after,
.header__drawer .drawer__lines::before {
	content: "";
	display: block;
	width: 30px;
	height: 1.5px;
	background-color: #333333;
	position: absolute;
	right: 0;
	transform: translateY(-50%);
	transition: transform 0.4s;
}

.header__drawer .drawer__lines::before {
	top: calc(50% - 9.7px);
	transform-origin: top right;
}

.header__drawer .drawer__lines::after {
	top: calc(50% + 9.7px);
	transform-origin: bottom right;
}

.header__drawer .drawer.is-active .drawer__lines {
	background-color: transparent;
}

.header__drawer .drawer.is-active .drawer__lines::before {
	transform: rotate(-45deg);
}

.header__drawer .drawer.is-active .drawer__lines::after {
	transform: rotate(45deg);
}

.header__drawer .drawer__nav--wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;
	height: 100vh;
	height: 100dvh;
	width: 100%;
	background-color: #d1d1d1;
	transition: opacity 0.4s, visibility 0.4s;
	padding: 70px 8vw 0;
	max-height: 100dvh;
	max-height: 100vh;
	overflow-y: auto; /* コンテンツがドロワーの高さを超えた場合にスクロールバーを表示 */
	opacity: 0;
	visibility: hidden;
}

.header__drawer .drawer__nav--wrapper.is-active {
	opacity: 1;
	visibility: visible;
}

.header__drawer .drawer__nav {
	padding: 30px 0;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
}

.header__drawer .drawerNav__item {
	padding-bottom: 24px;
	clip-path: inset(0 0 100% 0);
	transform: translateY(100%);
	position: relative;
}

.header__drawer .drawerNav__underline {
	content: "";
	display: inline-block;
	width: 100%;
	height: 1px;
	background-color: #ffffff;
	position: absolute;
	bottom: 3px;
	left: 0;
	opacity: 0;
	transform: translateY(100%);
}

.header__drawer .drawer__nav--wrapper.is-active .drawerNav__item {
	animation-name: animation_drawer-link-text;
	animation-duration: 0.8s;
	animation-fill-mode: forwards;
	animation-delay: 0.3s;
}

.header__drawer .drawer__nav--wrapper.is-active .drawerNav__underline {
	animation-name: animation_drawer-link-line;
	animation-duration: 1.2s;
	animation-fill-mode: forwards;
}

.header__drawer .drawerNav__item + .drawerNav__item {
	margin-top: 17px;
}

.header__drawer .drawerNav__link {
	display: block;
	width: 100%;
	color: #ffffff;
	transition: color 0.4s;
	font-family: "Cabin", sans-serif;
	font-size: min(1.25rem, 30px);
	font-weight: 700;
	letter-spacing: 0.06em;
	position: relative;
}

.header__drawer .drawer__logo--wrapper {
	margin: 4.2666666667vw 0;
	text-align: right;
}

.header__drawer .drawer__logo {
	display: inline-block;
	width: min(24.552vw, 138.105px);
	height: min(20.3493333333vw, 114.465px);
	opacity: 0;
	visibility: hidden;
}

.header__drawer .drawer__logo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.header__drawer .drawer__nav--wrapper.is-active .drawer__logo {
	animation-name: animation_drawer-logo;
	animation-duration: 1.2s;
	animation-fill-mode: forwards;
	animation-delay: 1.4s;
	animation-timing-function: ease-out;
}

.header__drawer .drawerNav__link.is-active::after {
	transform: translateY(-50%) rotate(-90deg);
}

.header__drawer .drawer__snsIcons {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 64px;
}

.header__drawer .drawer__snsIcon + .drawer__snsIcon {
	margin-left: 34px;
}

.header__drawer .snsIcon__link {
	width: clamp(30px, 8vw, 45px);
	height: clamp(30px, 8vw, 45px);
}

.header__drawer .snsIcon__link img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.promotion__movie,
.streetView {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
}

.googleMap {
	position: relative;
	width: 100%;
	padding-top: 50.89%;
}

.promotion__movie iframe,
.promotion__movie video,
.streetView iframe,
.googleMap iframe {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

.floatingBar {
	width: min(6.25vw, 120px);
	height: 100dvh;
	height: 100vh;
	background-color: #ffffff;
	opacity: 0.9;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 19;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.floatingBar__snsIcon--wrapper {
	transition: all 0.3s;
}

.floatingBar__snsIcon--wrapper img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.floatingBar__snsIcon--wrapper + .floatingBar__snsIcon--wrapper {
	margin-top: 45px;
}

.floatingBar__snsIcon--instagram {
	width: clamp(22px, 2.2916666667vw, 44px);
	height: clamp(22px, 2.2916666667vw, 44px);
}

.floatingBar__snsIcon--tiktok {
	width: clamp(19.5px, 2.03125vw, 39px);
	height: clamp(22px, 2.2916666667vw, 44px);
}

.footer {
	background-color: #3b4042;
}

.footer__inner.section__inner {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-direction: row;
}

.footer__text--big,
.footer__text--small,
.footer__tel,
.footer__copyRight {
	color: #ffffff;
}

.footer__text--big {
	font-family: "Cabin", sans-serif;
	font-size: clamp(72px, 4.6875vw, 90px);
	font-weight: 400;
	line-height: 1.2222222222;
	letter-spacing: 0.06em;
}

.footer__text--small {
	font-size: clamp(16px, 1.0416666667vw, 20px);
	font-weight: 400;
	line-height: 1.3;
	letter-spacing: 0.03em;
	margin-top: 14px;
}

.contact__link {
	transition: all 0.3s;
}

.footer__snsIcons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer__snsIcon--wrapper img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.footer__snsIcon--wrapper + .footer__snsIcon--wrapper {
	margin-left: clamp(9.6px, 0.625vw, 12px);
}

.footer__snsLink {
	transition: all 0.3s;
}

.footer__snsIcon--instagram {
	width: clamp(52.8px, 3.4375vw, 66px);
	height: clamp(52.8px, 3.4375vw, 66px);
}

.footer__snsIcon--hotpepperbeauty {
	width: clamp(121.6px, 7.9166666667vw, 152px);
	height: clamp(40.8px, 2.65625vw, 51px);
}

.footer__tel--wrapper {
	margin-top: 16px;
	text-align: center;
	position: relative;
	left: -5px;
}

.footer__tel {
	display: block;
	font-size: clamp(24px, 1.5625vw, 30px);
	font-weight: 400;
	line-height: clamp(18.4px, 1.1979166667vw, 23px);
	letter-spacing: 0.04em;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer__telIcon--wrapper {
	display: block;
	width: clamp(18.4px, 1.1979166667vw, 23px);
	height: clamp(18.4px, 1.1979166667vw, 23px);
	margin-right: clamp(3.2px, 0.2083333333vw, 4px);
	position: relative;
	top: 2px;
}

.footer__telIcon--wrapper img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.footer__copyRight {
	width: 100%;
	max-width: 1450px;
	margin: 0 auto;
	text-align: right;
	padding: 74px 0 56px;
	font-family: "Cabin", sans-serif;
	font-size: clamp(9.6px, 0.625vw, 12px);
	font-weight: 400;
	line-height: 1.5;
}

.header {
	width: 100%;
	height: 112px;
	padding: 0 clamp(24px, 4.1666666667vw, 80px);
	background-color: #ffffff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
}

.header__inner {
	max-width: 1450px;
	height: 100%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__left {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo {
	display: block;
	width: clamp(112px, 13.3854166667vw, 257px);
	height: clamp(30px, 3.5416666667vw, 68px);
	transition: all 0.3s;
}

.logo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.header__nav {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.headerNav__item + .headerNav__item {
	margin-left: min(2.5vw, 48px);
}

.headerNav__link {
	display: block;
	color: #333333;
	padding: 0 5px;
	font-size: clamp(14.4px, 0.9375vw, 18px);
	font-family: "Cabin", sans-serif;
	font-weight: 600;
	letter-spacing: 0.03em;
	transition: all 0.3s;
	position: relative;
}

.headerNav__link::after {
	content: "";
	width: 110%;
	height: 1px;
	background-color: #333333;
	position: absolute;
	bottom: -3px;
	left: 50%;
	transform: translateX(-50%) scale(0);
	transition: all 0.3s;
}

.headerNav__link:last-child {
	margin-left: min(0.46875vw, 9px);
}

.section {
	margin-top: 0;
	padding: 0 clamp(24px, 6.25vw, 120px);
	position: relative;
}

.section__inner {
	width: 100%;
	max-width: 1120px;
	padding: 130px 0;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
}

.sectionBackground__right::before,
.sectionBackground__left::before {
	content: "";
	max-width: calc(50vw + 780px);
	width: min(50vw + 560px + 11.4583333333vw, 100vw - clamp(24px, 6.25vw, 120px) / 2);
	height: calc(100% - 130px);
	background-color: #ffffff;
	position: absolute;
	z-index: 0;
	bottom: 0;
}

.sectionBackground__right::before {
	right: 0;
}

.sectionBackground__left::before {
	left: 0;
}

.sectionBackground__right .section__title,
.sectionBackground__left .section__title {
	margin-top: -37px;
}

.section__title {
	width: 100%;
	font-family: "Cabin", sans-serif;
	font-size: clamp(54.4px, 3.5416666667vw, 68px);
	font-weight: 700;
	letter-spacing: 0.06em;
}

.section__body {
	margin-top: 70px;
	width: 100%;
}

.toTop--wrapper {
	width: min(6.25vw, 120px);
	height: clamp(32px, 2.0833333333vw, 40px);
	position: fixed;
	bottom: 16px;
	right: 0;
	z-index: 30;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.toTop {
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
	cursor: pointer;
	width: clamp(32px, 2.0833333333vw, 40px);
	height: clamp(32px, 2.0833333333vw, 40px);
	border-radius: 50%;
	border: 2px solid #d1d1d1;
	background-color: #ffffff;
	position: relative;
}

.toTop::after {
	content: "";
	display: block;
	width: clamp(9.6px, 0.625vw, 12px);
	height: clamp(9.6px, 0.625vw, 12px);
	border-top: 2px solid #d1d1d1;
	border-right: 2px solid #d1d1d1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -32%) rotate(-45deg);
}

.toTop.is-visible {
	opacity: 1;
	visibility: visible;
}

.section.section__access {
	background-color: #dbdfea;
}

.section__access .section__body {
	margin-top: 92px;
}

.googleMap--rapper {
	width: 100%;
	max-width: 1120px;
	margin-top: 51px;
}

.access__items {
	margin-top: 60px;
}

.access__locationName,
.access__item {
	position: relative;
}

.access__locationName::after,
.access__item::after {
	content: "";
	width: 3px;
	height: 30px;
	background-color: #000000;
	position: absolute;
	top: -5px;
	left: 0;
}

.access__item::before {
	content: "";
	width: 3px;
	height: 100%;
	background-color: #e2e2e2;
	position: absolute;
	top: -5px;
	left: 0;
}

.access__item + .access__item {
	margin-top: 56px;
}

.access__locationName,
.access__title,
.access__text {
	font-size: clamp(16px, 1.0416666667vw, 20px);
	font-weight: 400;
	letter-spacing: 0.03em;
	padding-left: clamp(32px, 2.0833333333vw, 40px);
}

.access__locationName,
.access__title {
	line-height: 2;
}

.access__text {
	line-height: 1.3;
	margin-top: 11px;
}

.section.section__concept {
	background-color: #a0bfe0;
}

.concept__item {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.concept__item + .concept__item {
	margin-top: 80px;
}

.concept__item .concept__text {
	margin: 0 0 0 3.125vw;
}

.concept__item.u__flexbox--reverse .concept__text {
	margin: 0 3.125vw 0 0;
}

.concept__image {
	width: 44.6428571429%;
	flex-shrink: 0;
}

.concept__image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.conceptText__p {
	font-size: clamp(16px, 1.0416666667vw, 20px);
	font-weight: 300;
	line-height: 2;
	letter-spacing: 0.03em;
}

.conceptText__p + .conceptText__p {
	margin-top: 40px;
}

.fv {
	width: 100%;
	height: calc(100vh - 112px);
	height: calc(100dvh - 112px);
	aspect-ratio: 1920/968;
	max-height: 968px;
	min-height: 560px;
	margin-top: 112px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.fv__background--wrapper {
	width: 100%;
	height: 100%;
}

.fv__background--wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.fv__logo--wrapper {
	width: clamp(564.8px, 36.7708333333vw, 706px);
	height: auto;
	aspect-ratio: 706/188;
	position: absolute;
}

.fv__logo--wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.section__program {
	background-color: #a0bfe0;
}

.program__nav {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.program__navItem {
	width: 33.3%;
	position: relative;
}

.program__navItem + .program__navItem::before {
	content: "";
	display: block;
	width: 1px;
	height: 85px;
	background-color: #707070;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	z-index: 1;
}

.program__navLink {
	display: block;
	width: 100%;
	background-color: #f6f5f4;
	padding: 37px 0 36px;
	text-align: center;
	transition: all 0.3s;
}

/*下からの距離が変化して全体が下→上→下に動く*/

.program__navLinkText {
	font-family: "Cabin", sans-serif;
	font-size: clamp(17.6px, 1.1458333333vw, 22px);
	font-weight: 400;
	letter-spacing: 0.06em;
	position: relative;
}

.program__navLinkText::after {
	content: "";
	display: block;
	width: clamp(10.4px, 0.6770833333vw, 13px);
	height: clamp(7.2px, 0.46875vw, 9px);
	background: url(../images/img-icon-arrow-down.svg) no-repeat center center/contain;
	position: absolute;
	top: 50%;
	right: clamp(-24px, -1.25vw, -19.2px);
	transform: translateY(-50%);
}

.program__items {
	padding-top: 80px;
}

.program__item + .program__item {
	padding-top: 80px;
}

.program__title {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.program__title--eg {
	font-family: "Cabin", sans-serif;
	font-size: clamp(25.6px, 1.6666666667vw, 32px);
	font-weight: 500;
	line-height: 1.21875;
	letter-spacing: 0.06em;
	position: relative;
}

.program__title--eg::after {
	content: "";
	display: block;
	width: clamp(156px, 10.15625vw, 195px);
	height: 3px;
	background-color: #333333;
	position: absolute;
	bottom: -13px;
	left: 0;
}

.program__title--jp {
	font-size: clamp(16px, 1.0416666667vw, 20px);
	font-weight: 400;
	line-height: 2;
	letter-spacing: 0.03em;
	margin-left: clamp(16px, 1.0416666667vw, 20px);
}

.program__body {
	margin-top: 53px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.program__image {
	width: 41.5178571429%;
	flex-shrink: 0;
}

.program__image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.program__text {
	height: 100%;
	margin-left: clamp(75.2px, 4.8958333333vw, 94px);
	border-top: 1px solid #333333;
	border-bottom: 1px solid #333333;
	padding: 44px 0 22px;
}

.program__time {
	font-size: clamp(17.6px, 1.1458333333vw, 22px);
	font-weight: 400;
	line-height: 1.2272727273;
	letter-spacing: 0.06em;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.program__time::before {
	content: "";
	display: block;
	width: clamp(25.6px, 1.6666666667vw, 32px);
	height: clamp(25.6px, 1.6666666667vw, 32px);
	background: url(../images/img-icon-clock.svg) no-repeat center center/contain;
	margin-right: clamp(17.6px, 1.1458333333vw, 22px);
}

.program__price {
	margin-top: 20px;
	font-family: "Montserrat", sans-serif;
	font-size: clamp(32px, 2.0833333333vw, 40px);
	font-weight: 600;
	line-height: 1.225;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.program__price--note {
	font-size: clamp(14px, 1.0416666667vw, 20px);
	font-weight: 400;
	line-height: 2.5;
	letter-spacing: 0.03em;
	margin-left: clamp(16px, 1.0416666667vw, 20px);
}

.icon__yen {
	font-family: "Montserrat", sans-serif;
	font-size: clamp(19.2px, 1.25vw, 24px);
	font-weight: 600;
	line-height: 1.2083333333;
	margin-right: clamp(9.6px, 0.625vw, 12px);
}

.program__description {
	margin-top: 32px;
	font-size: clamp(16px, 1.0416666667vw, 20px);
	font-weight: 400;
	line-height: 2.5;
	letter-spacing: 0.03em;
}

.section__promotion {
	background-color: #f6f5f4;
}

.section.section__promotion .section__inner {
	width: 91.4285714286%;
}

.promotion__movie--rapper {
	width: 100%;
	max-width: 1024px;
}

.section__streetView {
	background-color: #f6f5f4;
}

.section.section__streetView .section__inner {
	width: 91.4285714286%;
	padding-bottom: 0;
}

.streetView--rapper {
	width: 100%;
	max-width: 1024px;
}

.section.section__trainer {
	background-color: #f6f5f4;
}

.trainer__items {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
}

.trainer__item {
	width: 48%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
}

.trainer__item:nth-of-type(n + 3) {
	margin-top: 104px;
}

.trainer__content--wrapper {
	width: clamp(307.8px, 19.9479166667vw, 383px);
}

.trainer__image {
	width: 100%;
	height: auto;
	aspect-ratio: 380/380;
	margin: 0 auto;
	border-radius: 50%;
}

.trainer__image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.trainer__name {
	width: 100%;
	font-size: clamp(17.6px, 1.1458333333vw, 22px);
	font-weight: 400;
	line-height: 1.6363636364;
	margin-top: 40px;
	text-align: center;
}

.trainer__text {
	width: 100%;
	font-size: clamp(12.8px, 0.8333333333vw, 16px);
	font-weight: 300;
	line-height: 2.5;
	margin-top: 7px;
}

.section.section__voice {
	background-color: #ffffff;
}

.voice__item {
	width: 100%;
	padding: 32px clamp(19.2px, 1.25vw, 24px);
	border: 1px solid #707070;
}

.voice__item + .voice__item {
	margin-top: 44px;
}

.voice__name {
	font-size: clamp(19.2px, 1.25vw, 24px);
	font-weight: 400;
	line-height: 0.6666666667;
}

.voice__text {
	margin-top: 23px;
	font-size: clamp(16px, 1.0416666667vw, 20px);
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.03em;
}

.u__flexbox--reverse {
	flex-direction: row-reverse;
}

.u__color--blue {
	color: #a0bfe0;
}

.u__fontFamily--eg {
	font-family: "Cabin", sans-serif;
}

.u__fontWeight--w6 {
	font-weight: 600;
}

.only-pc {
	display: block;
	visibility: visible;
}

.only-sp {
	display: none;
	visibility: hidden;
}

@media (hover: hover) and (pointer: fine) {

.btn__reservation:hover {
	opacity: 0.6;
	background-color: #8294c4;
}

.btn__reservation:hover::after {
	animation: reservationArrowMove 1.2s ease-in-out infinite;
}

.floatingBar__snsIcon--wrapper:hover {
	opacity: 0.6;
	animation: iconMove 1.2s ease-in-out infinite;
}

.contact__link:hover {
	opacity: 0.6;
}

.footer__snsLink:hover {
	opacity: 0.6;
}

.logo:hover {
	opacity: 0.6;
}

.headerNav__link:hover::after {
	transform: translateX(-50%) scale(1);
}

.toTop:hover {
	opacity: 0.6;
}

.program__navLink:hover {
	opacity: 0.6;
}

.program__navLink:hover .program__navLinkText::after {
	animation: arrowMove 1.2s ease-in-out infinite;
}

}

@media screen and (min-width: 769px) {

a[href*="tel:"] {
	pointer-events: none;
	cursor: default;
	text-decoration: none;
}

}

@media screen and (max-width: 1024px) {

html {
	font-size: 14px;
}

body.safari .subPageSection__about::after {
	width: calc(100vw - 308px - clamp(24px, 6.25vw, 120px));
}

body.firefox .subPageSection__about::after {
	width: calc(100vw - 292px - clamp(24px, 6.25vw, 120px));
}

.drawer__inner {
	padding-left: 0;
}

.header {
	height: 90px;
}

.headerNav__link {
	font-size: 0.875rem;
}

.headerNav__link:last-child {
	margin-left: 0;
}

.concept__item {
	align-items: flex-start;
}

.fv {
	height: calc(100vh - 90px);
	height: calc(100dvh - 90px);
	margin-top: 90px;
}

}

@media screen and (max-width: 768px) {

html {
	font-size: 12px;
}

body.safari .subPageSection__about::after {
	background: url(../images/img-sub-about-background-SP.png) no-repeat center center/cover;
	width: 100%;
	height: calc(100% - 128px);
	top: 128px;
}

body.firefox .subPageSection__about::after {
	background: url(../images/img-sub-about-background-SP.png) no-repeat center center/cover;
	width: 100%;
	height: calc(100% - 128px);
	top: 128px;
}

.btn--wrapper {
	padding: 56px min(8vw, 30px) 71px;
}

.btn__reservation {
	height: clamp(64px, 21.3333333333vw, 96px);
	line-height: clamp(64px, 21.3333333333vw, 96px);
	font-size: clamp(12.8px, 4.2666666667vw, 19.2px);
	line-height: clamp(64px, 21.3333333333vw, 96px);
	letter-spacing: 0.03em;
}

.btn__reservation::after {
	width: clamp(14.4px, 4.8vw, 21.6px);
	height: clamp(5.6px, 1.8666666667vw, 8.4px);
	margin-left: clamp(10.4px, 3.4666666667vw, 15.6px);
}

.googleMap {
	padding-top: 101%;
}

.floatingBar {
	display: none;
}

.footer__inner.section__inner {
	display: block;
	padding-top: 51px;
}

.footer__text--big {
	font-size: clamp(40px, 13.3333333333vw, 60px);
	line-height: 1.22;
	text-align: center;
}

.footer__text--small {
	font-size: clamp(11.2px, 3.7333333333vw, 16.8px);
	line-height: 1.7142857143;
	text-align: center;
}

.footer__snsIcons {
	display: block;
}

.footer__snsIcon--wrapper {
	margin: 0 auto;
	margin-top: 30px;
}

.footer__snsIcon--wrapper + .footer__snsIcon--wrapper {
	margin-left: auto;
}

.footer__snsIcon--instagram {
	width: clamp(36px, 12vw, 54px);
	height: clamp(36px, 12vw, 54px);
}

.footer__snsIcon--hotpepperbeauty {
	width: clamp(121.6px, 40.5333333333vw, 182.4px);
	height: clamp(40.8px, 13.6vw, 61.2px);
}

.footer__tel--wrapper {
	margin: 0 auto;
	width: fit-content;
	margin-top: 26px;
}

.footer__tel {
	font-size: clamp(20.8px, 6.9333333333vw, 31.2px);
	line-height: clamp(18.4px, 6.1333333333vw, 27.6px);
}

.footer__telIcon--wrapper {
	width: clamp(18.4px, 6.1333333333vw, 27.6px);
	height: clamp(18.4px, 6.1333333333vw, 27.6px);
	margin-right: clamp(3.2px, 1.0666666667vw, 4.8px);
}

.footer__copyRight {
	font-size: clamp(9.6px, 3.2vw, 14.4px);
	text-align: center;
	padding: 11px 0 28px;
}

.header {
	height: 70px;
	padding: 0 clamp(30px, 8vw, 30px);
}

.section {
	margin-top: 0;
	padding: 0 min(8vw, 30px);
}

.section__inner {
	margin: 0 auto;
	padding: 60px 0;
}

.sectionBackground__right::before,
.sectionBackground__left::before {
	width: calc(100vw - min(18px, 4.8vw));
	height: calc(100% - 60px);
}

.sectionBackground__right .section__title,
.sectionBackground__left .section__title {
	margin-top: -13px;
}

.section__title {
	font-size: clamp(19.2px, 6.4vw, 28.8px);
}

.section__body {
	margin-top: 40px;
}

.toTop--wrapper {
	bottom: clamp(6.4px, 2.1333333333vw, 9.6px);
	right: clamp(6.4px, 2.1333333333vw, 9.6px);
}

.toTop {
	width: clamp(19.2px, 6.4vw, 28.8px);
	height: clamp(19.2px, 6.4vw, 28.8px);
}

.toTop::after {
	width: clamp(6.4px, 2.1333333333vw, 9.6px);
	height: clamp(6.4px, 2.1333333333vw, 9.6px);
}

.section__access .section__body {
	margin-top: 35px;
}

.googleMap--rapper {
	margin-top: 38px;
}

.access__items {
	margin-top: 42px;
}

.access__locationName::after {
	top: 0;
}

.access__item + .access__item {
	margin-top: 42px;
}

.access__locationName,
.access__title {
	font-size: clamp(14.4px, 4.8vw, 21.6px);
}

.access__text {
	margin-top: 0;
	font-size: clamp(11.2px, 3.7333333333vw, 16.8px);
	line-height: 1.7142857143;
}

.concept__item {
	flex-direction: column;
}

.concept__item + .concept__item {
	margin-top: 58px;
}

.concept__item .concept__text {
	margin: 36px 0 0;
}

.concept__item.u__flexbox--reverse .concept__text {
	margin: 36px 0;
}

.concept__image {
	width: 100%;
}

.conceptText__p {
	font-size: clamp(11.2px, 3.7333333333vw, 16.8px);
	line-height: 1.7142857143;
}

.conceptText__p + .conceptText__p {
	margin-top: 24px;
}

.fv {
	width: 100%;
	height: auto;
	aspect-ratio: 375/400;
	min-height: initial;
	height: calc(100vh - 70px);
	height: calc(100dvh - 70px);
	max-width: 100%;
	margin-top: 70px;
}

.fv__logo--wrapper {
	width: max(252px, 84vw);
}

.program__nav {
	display: block;
}

.program__navItem {
	width: 100%;
}

.program__navItem + .program__navItem::before {
	width: 100%;
	height: 1px;
	top: 0;
}

.program__navLink {
	padding: 41px 0 40px;
}

.program__navLinkText {
	font-size: clamp(14.4px, 4.8vw, 21.6px);
}

.program__navLinkText::after {
	width: clamp(10.4px, 3.4666666667vw, 15.6px);
	height: clamp(7.2px, 2.4vw, 10.8px);
	right: clamp(-28.8px, -5.3333333333vw, -19.2px);
}

.program__items {
	padding-top: 19px;
}

.program__item:first-of-type {
	padding-top: 24px;
}

.program__item + .program__item {
	padding-top: 48px;
}

.program__title--eg {
	font-size: clamp(14.4px, 4.8vw, 21.6px);
}

.program__title--eg::after {
	width: clamp(85.6px, 28.5333333333vw, 128.4px);
	bottom: -12px;
}

.program__title--jp {
	font-size: clamp(14.4px, 4.8vw, 21.6px);
	margin-left: clamp(9.6px, 3.2vw, 14.4px);
}

.program__body {
	display: block;
	margin-top: 22px;
}

.program__image {
	width: 100%;
}

.program__text {
	margin-top: 24px;
	margin-left: 0;
	padding: 21px 0 13px;
}

.program__time {
	font-size: clamp(12.8px, 4.2666666667vw, 19.2px);
}

.program__time::before {
	width: clamp(18.4px, 6.1333333333vw, 27.6px);
	height: clamp(18.4px, 6.1333333333vw, 27.6px);
	margin-right: clamp(8px, 2.6666666667vw, 12px);
}

.program__price {
	font-size: clamp(20.8px, 6.9333333333vw, 31.2px);
	margin-top: 15px;
}

.program__price--note {
	font-size: clamp(11.2px, 3.7333333333vw, 16.8px);
	line-height: 1.7142857143;
	margin-top: 17px;
}

.icon__yen {
	font-size: clamp(12.8px, 4.2666666667vw, 19.2px);
	margin-right: clamp(8px, 2.6666666667vw, 12px);
}

.program__description {
	font-size: clamp(11.2px, 3.7333333333vw, 16.8px);
	line-height: 1.7142857143;
	margin-top: 17px;
}

.section.section__promotion {
	padding: 0;
}

.section.section__promotion .section__inner {
	width: 100%;
	padding: 46px 0;
}

.section.section__streetView {
	padding: 0;
}

.section.section__streetView .section__inner {
	width: 100%;
	padding: 71px 0 0;
}

.trainer__items {
	flex-direction: column;
}

.trainer__item {
	width: 100%;
	max-width: 317px;
	margin: 0 auto;
}

.trainer__item:nth-of-type(n + 2) {
	margin-top: 64px;
}

.trainer__content--wrapper {
	width: 100%;
}

.trainer__image {
	width: clamp(144px, 48vw, 306px);
}

.trainer__name {
	font-size: clamp(14.4px, 4.8vw, 21.6px);
	line-height: 1.2222222222;
	margin-top: 20px;
}

.trainer__text {
	font-size: clamp(11.2px, 3.7333333333vw, 16.8px);
	line-height: 1.8571428571;
	letter-spacing: -0.07rem;
	margin-top: 25px;
}

.voice__item {
	padding: 26px clamp(19.2px, 6.4vw, 28.8px) 28px;
}

.voice__name {
	font-size: clamp(14.4px, 4.8vw, 21.6px);
	line-height: 1.2222222222;
}

.voice__text {
	margin-top: 28px;
	font-size: clamp(11.2px, 3.7333333333vw, 16.8px);
	line-height: 1.7142857143;
}

.only-pc {
	display: none;
	visibility: hidden;
}

.only-sp {
	display: block;
	visibility: visible;
}

}

@keyframes reservationArrowMove {

0% {
	transform: translateX(0px);
}

50% {
	transform: translateX(10px);
}

100% {
	transform: translateX(0px);
}

}

@keyframes animation_drawer-link-text {

0% {
	clip-path: inset(0 0 100% 0);
	transform: translateY(100%);
}

100% {
	clip-path: inset(0);
	transform: translateY(0);
}

}

@keyframes animation_drawer-link-line {

0% {
	opacity: 0;
	transform: translateY(100px);
}

100% {
	opacity: 1;
	transform: translateY(0);
}

}

@keyframes animation_drawer-logo {

0% {
	opacity: 0;
	visibility: hidden;
}

100% {
	opacity: 1;
	visibility: visible;
}

}

@keyframes iconMove {

0% {
	transform: scale(1);
}

50% {
	transform: scale(1.2);
}

100% {
	transform: scale(1);
}

}

@keyframes arrowMove {

0% {
	transform: translateY(-50%);
}

50% {
	transform: translateY(0);
}

100% {
	transform: translateY(-50%);
}

}

