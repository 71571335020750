@use "global" as *;

.drawer__inner {
	position: relative;
	width: 100%;

	padding-left: vw(strip-unit($canvas-size-pc), 44);

	@include mq("tab") {
		padding-left: 0;
	}
}

// ハンバーガーメニュー
.header__drawer {
	.drawer {
		display: block;
		width: 30px;
		height: 24px;
		margin-left: auto;
		position: fixed;
		z-index: $z-index-drawer;
		border: none;
		background-color: transparent;
		// right: 1px;

		right: min(vw(strip-unit($canvas-size-sp), strip-unit($padding-sp)), $padding-sp);
		top: 23px;
	}

	// .drawer.is-active {
	// 	animation-name: animation_drawer-all-rotate;
	// 	animation-duration: 1s;
	// 	animation-fill-mode: forwards;
	// }
	// .drawer.is-inactive {
	// 	animation-name: animation_drawer-all-rotate-reverse;
	// 	animation-duration: 1s;
	// 	animation-fill-mode: forwards;
	// }

	.drawer__lines {
		display: block;
		width: 30px;
		height: 1.5px;
		background-color: $color-black;

		transition: all 0.3s;

		&::after,
		&::before {
			content: "";
			display: block;
			width: 30px;
			height: 1.5px;
			background-color: $color-black;
			position: absolute;
			right: 0;
			transform: translateY(-50%);

			transition: transform 0.4s;
		}

		&::before {
			top: calc(50% - 9.7px);
			transform-origin: top right;
		}

		&::after {
			top: calc(50% + 9.7px);
			transform-origin: bottom right;
		}
	}

	.drawer.is-active .drawer__lines {
		background-color: transparent;

		&::before {
			transform: rotate(-45deg);
		}
		&::after {
			transform: rotate(45deg);
		}
	}

	// .drawer.is-active .drawer__lines {
	// 	&::before {
	// 		animation-name: animation_drawer-line-before;
	// 		animation-duration: 1s;
	// 		animation-fill-mode: forwards;
	// 	}
	// 	&::after {
	// 		animation-name: animation_drawer-line-after;
	// 		animation-duration: 1s;
	// 		animation-fill-mode: forwards;
	// 	}
	// }
	// .drawer.is-inactive .drawer__lines {
	// 	&::before {
	// 		animation-name: animation_drawer-line-before-reverse;
	// 		animation-duration: 1s;
	// 		animation-fill-mode: forwards;
	// 	}
	// 	&::after {
	// 		animation-name: animation_drawer-line-after-reverse;
	// 		animation-duration: 1s;
	// 		animation-fill-mode: forwards;
	// 	}
	// }

	// .drawer__dots {
	// 	&::after,
	// 	&::before {
	// 		content: "";
	// 		display: block;
	// 		width: 8px;
	// 		height: 8px;
	// 		border-radius: 50%;
	// 		background-color: $color-primary;
	// 		position: absolute;
	// 		left: 29px;

	// 		transform: translate(-29px, -50%);
	// 	}
	// }
	// .drawer__dots:before {
	// 	top: calc(50% - 7.5px);
	// }
	// .drawer__dots:after {
	// 	top: calc(50% + 7.5px);
	// }

	// .drawer.is-active .drawer__dots {
	// 	&::before {
	// 		animation-name: animation_drawer-dots-before;
	// 		animation-duration: 1s;
	// 		animation-fill-mode: forwards;
	// 	}
	// 	&::after {
	// 		animation-name: animation_drawer-dots-after;
	// 		animation-duration: 1s;
	// 		animation-fill-mode: forwards;
	// 	}
	// }
	// .drawer.is-inactive .drawer__dots {
	// 	&::before {
	// 		animation-name: animation_drawer-dots-before-reverse;
	// 		animation-duration: 1s;
	// 		animation-fill-mode: forwards;
	// 	}
	// 	&::after {
	// 		animation-name: animation_drawer-dots-after-reverse;
	// 		animation-duration: 1s;
	// 		animation-fill-mode: forwards;
	// 	}
	// }

	// ハンバーガーアイコン：アニメーション -------------------------
	// 全体：横→縦へ
	// @keyframes animation_drawer-all-rotate {
	// 	0% {
	// 		transform: rotate(0) translateY(0);
	// 	}

	// 	50% {
	// 		transform: rotate(90deg) translateY(-10px);
	// 	}
	// 	100% {
	// 		transform: rotate(90deg) translateY(-10px);
	// 	}
	// }
	// @keyframes animation_drawer-all-rotate-reverse {
	// 	0% {
	// 		transform: rotate(90deg) translateY(-10px);
	// 	}
	// 	50% {
	// 		transform: rotate(90deg) translateY(-10px);
	// 	}
	// 	100% {
	// 		transform: rotate(0) translateY(0);
	// 	}
	// }
	// // 全体：背景色赤→白
	// @keyframes animation_drawer-all-color {
	// 	0% {
	// 		// transform: rotate(0);
	// 		background-color: $color-primary;
	// 	}

	// 	100% {
	// 		// transform: rotate(90deg);
	// 		background-color: $color-white;
	// 	}
	// }

	// 口
	// @keyframes animation_drawer-line-before {
	// 	0% {
	// 		transform: translateY(-50%);
	// 		background-color: $color-primary;
	// 	}
	// 	50% {
	// 		transform: translateY(-50%);
	// 		background-color: $color-white;
	// 	}

	// 	100% {
	// 		transform: rotate(66deg) translate(8px, -5px);
	// 		background-color: $color-white;
	// 	}
	// }
	// @keyframes animation_drawer-line-before-reverse {
	// 	0% {
	// 		transform: rotate(66deg) translate(8px, -5px);
	// 		background-color: $color-white;
	// 	}
	// 	50% {
	// 		transform: translateY(-50%);
	// 		background-color: $color-primary;
	// 	}

	// 	100% {
	// 		transform: translateY(-50%);
	// 		background-color: $color-primary;
	// 	}
	// }

	// @keyframes animation_drawer-line-after {
	// 	0% {
	// 		transform: translateY(-50%);
	// 		background-color: $color-primary;
	// 	}
	// 	50% {
	// 		transform: translateY(-50%);
	// 		background-color: $color-white;
	// 	}

	// 	100% {
	// 		transform: rotate(-66deg) translate(12px, 3px);
	// 		background-color: $color-white;
	// 	}
	// }
	// @keyframes animation_drawer-line-after-reverse {
	// 	0% {
	// 		transform: rotate(-66deg) translate(12px, 3px);
	// 		background-color: $color-white;
	// 	}
	// 	50% {
	// 		transform: translateY(-50%);
	// 		background-color: $color-primary;
	// 	}

	// 	100% {
	// 		transform: translateY(-50%);
	// 		background-color: $color-primary;
	// 	}
	// }

	// // 目
	// @keyframes animation_drawer-dots-before {
	// 	0% {
	// 		transform: translate(-29px, -50%);
	// 		background-color: $color-primary;
	// 	}
	// 	50% {
	// 		transform: translate(-29px, -50%);
	// 		background-color: $color-white;
	// 	}
	// 	100% {
	// 		transform: translate(-12px, -9px);
	// 		background-color: $color-white;
	// 	}
	// }
	// @keyframes animation_drawer-dots-before-reverse {
	// 	0% {
	// 		transform: translate(-12px, -9px);
	// 		background-color: $color-white;
	// 	}
	// 	50% {
	// 		transform: translate(-29px, -50%);
	// 		background-color: $color-primary;
	// 	}
	// 	100% {
	// 		transform: translate(-29px, -50%);
	// 		background-color: $color-primary;
	// 	}
	// }
	// @keyframes animation_drawer-dots-after {
	// 	0% {
	// 		transform: translate(-29px, -50%);
	// 		background-color: $color-primary;
	// 	}
	// 	50% {
	// 		transform: translate(-29px, -50%);
	// 		background-color: $color-white;
	// 	}
	// 	100% {
	// 		transform: translate(-12px, 2px);
	// 		background-color: $color-white;
	// 	}
	// }
	// @keyframes animation_drawer-dots-after-reverse {
	// 	0% {
	// 		transform: translate(-12px, 2px);
	// 		background-color: $color-white;
	// 	}
	// 	50% {
	// 		transform: translate(-29px, -50%);
	// 		background-color: $color-primary;
	// 	}
	// 	100% {
	// 		transform: translate(-29px, -50%);
	// 		background-color: $color-primary;
	// 	}
	// }

	// ハンバーガーナビゲーション -------------------------
	.drawer__nav--wrapper {
		position: fixed;
		top: 0;
		// left: -100%;
		left: 0;
		z-index: 9;
		height: 100vh;
		height: 100dvh;
		width: 100%;
		background-color: $color-background-drawer;
		// padding-top: 47px;
		transition: opacity 0.4s, visibility 0.4s;

		padding: $header-height-sp vw(strip-unit($canvas-size-sp), strip-unit($padding-sp)) 0;

		// スクロール用設定 ＝＝＝＝＝＝＝＝＝＝＝＝
		max-height: 100dvh;
		max-height: 100vh;
		overflow-y: auto; /* コンテンツがドロワーの高さを超えた場合にスクロールバーを表示 */
		// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

		opacity: 0;
		visibility: hidden;

		// display: flex;
		// // align-items: center;
		// justify-content: center;
	}
	.drawer__nav--wrapper.is-active {
		// left: 0;
		opacity: 1;
		visibility: visible;

		// @include mq("over-tab-s") {
		// 	opacity: 0;
		// 	visibility: hidden;
		// }
	}
	.drawer__nav {
		// padding-top: 40px;
		// padding: 30px vw(strip-unit($canvas-size-sp), strip-unit($padding-sp));
		padding: 30px 0;

		width: 100%;
		max-width: 400px;
		margin: 0 auto;
	}

	.drawerNav__item {
		padding-bottom: 24px;
		// border-bottom: 1px solid $color-white;

		clip-path: inset(0 0 100% 0);
		transform: translateY(100%);
		// transition: all 5s;
		position: relative;

		// &::after {
		// 	content: "";
		// 	display: inline-block;
		// 	width: 100%;
		// 	height: 1px;
		// 	background-color: $color-white;

		// 	position: absolute;
		// 	bottom: 3px;
		// 	left: 0;

		// 	clip-path: inset(0 0 100% 0);
		// 	transform: translateY(100%);
		// }
	}

	.drawerNav__underline {
		content: "";
		display: inline-block;
		width: 100%;
		height: 1px;
		background-color: $color-white;

		position: absolute;
		bottom: 3px;
		left: 0;

		opacity: 0;
		transform: translateY(100%);
	}

	.drawer__nav--wrapper.is-active .drawerNav__item {
		animation-name: animation_drawer-link-text;
		animation-duration: 0.8s;
		animation-fill-mode: forwards;
		animation-delay: 0.3s;
		// animation-timing-function: ease-out;
	}

	.drawer__nav--wrapper.is-active .drawerNav__underline {
		animation-name: animation_drawer-link-line;
		animation-duration: 1.2s;
		animation-fill-mode: forwards;
		// animation-delay: 0.2s;
		// animation-timing-function: ease-out;
	}

	.drawerNav__item + .drawerNav__item {
		margin-top: 17px;
		// margin-top: vw(strip-unit($canvas-size-sp), 24);

		// 小さいスマホ向け
		// @include mq("sp-s") {
		// 	margin-top: 21px;
		// }
	}
	.drawerNav__link {
		display: block;
		width: 100%;

		color: $color-white;
		// font-weight: 900;
		transition: color 0.4s;
		// font-size: 0.875rem;

		font-family: $font-family-eg;
		// font-size: 24px;
		font-size: min(rem(20), calc(20 * 1.5px));
		// font-size: rem(24);
		font-weight: $font-weight-bold;
		letter-spacing: calc(60 / 1000 * 1em);

		// padding-left: 16px;

		position: relative;

		// // 小さいスマホ向け
		// @include mq("sp-s") {
		// 	font-size: 18px;
		// }

		// &::before {
		// 	content: "";
		// 	display: inline-block;
		// 	width: 8px;
		// 	height: 8px;
		// 	background-color: $color-white;
		// 	border-radius: 50%;

		// 	position: absolute;
		// 	top: 50%;
		// 	left: 0;
		// 	// transform: translateY(-50%);
		// }
	}

	.drawer__logo--wrapper {
		// margin-top: 16px;
		// margin-top: vw(strip-unit($canvas-size-sp), 16);
		margin: vw(strip-unit($canvas-size-sp), 16) 0;
		text-align: right;
	}
	.drawer__logo {
		display: inline-block;
		// width: 92.07px;
		// height: 76.31px;
		// width: vw(strip-unit($canvas-size-sp), 92.07);
		// height: vw(strip-unit($canvas-size-sp), 76.31);
		width: min(vw(strip-unit($canvas-size-sp), 92.07), calc(92.07 * 1.5px));
		height: min(vw(strip-unit($canvas-size-sp), 76.31), calc(76.31 * 1.5px));

		opacity: 0;
		visibility: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.drawer__nav--wrapper.is-active .drawer__logo {
		animation-name: animation_drawer-logo;
		animation-duration: 1.2s;
		animation-fill-mode: forwards;
		animation-delay: 1.4s;
		animation-timing-function: ease-out;
	}

	.drawerNav__link.is-active::after {
		transform: translateY(-50%) rotate(-90deg);
	}
}

// アニメーション
// メニューテキスト
@keyframes animation_drawer-link-text {
	0% {
		clip-path: inset(0 0 100% 0);
		transform: translateY(100%);
	}

	100% {
		clip-path: inset(0);
		transform: translateY(0);
	}
}
// 下線
@keyframes animation_drawer-link-line {
	0% {
		// clip-path: inset(0 0 100% 0);
		opacity: 0;
		transform: translateY(100px);
	}

	100% {
		// clip-path: inset(0);
		opacity: 1;
		transform: translateY(0);
	}
}

// ロゴ
@keyframes animation_drawer-logo {
	0% {
		opacity: 0;
		visibility: hidden;
	}

	100% {
		opacity: 1;
		visibility: visible;
	}
}

// SNSアイコン

.header__drawer {
	.drawer__snsIcons {
		display: flex;
		align-items: center;
		justify-content: center;

		margin-top: 64px;
	}

	.drawer__snsIcon + .drawer__snsIcon {
		margin-left: 34px;
	}

	.snsIcon__link {
		width: clamp(30px, vw(strip-unit($canvas-size-sp), 30), calc(30 * 1.5px));
		height: clamp(30px, vw(strip-unit($canvas-size-sp), 30), calc(30 * 1.5px));

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
